import * as React from "react"

function Svg(props) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 200 200"
      enableBackground="new 0 0 200 200"
      xmlSpace="preserve"
      {...props}
    >
      <rect fill="#0A9FDA" width={200} height={200} />
      <g>
        <path
          fill="#FFFFFF"
          d="M148.7,83.4c-1.2,0-2.4-0.4-3.4-1l-23.9,18.5l8.5,28.3c1.7,0.1,3.3,0.9,4.3,2.3c0.1,0.1,0.1,0.2,0.2,0.4 l25.5-19.4l-9.4-29.4c-0.1,0-0.1,0.1-0.2,0.1C149.8,83.3,149.2,83.4,148.7,83.4"
        />
        <path
          fill="#FFFFFF"
          d="M123.6,135.2c0-0.5,0.1-1,0.2-1.5l-23.7-17.5l-24,17.8c0.1,0.5,0.2,1,0.2,1.5c0,1.2-0.3,2.3-1,3.2 c-0.1,0.1-0.2,0.2-0.3,0.3l24.9,18.9l25-18.9c-0.1-0.1-0.1-0.1-0.2-0.2C124,137.8,123.6,136.5,123.6,135.2"
        />
        <path
          fill="#FFFFFF"
          d="M103.7,47.1l9.4,28.1h30c0.6-2,2-2.8,3.9-3.4c0,0,0-0.1,0-0.1l-9.4-29.7H106c0,0,0,0.2,0,0.2 C106,44.1,105.1,46,103.7,47.1"
        />
        <path
          fill="#FFFFFF"
          d="M70.1,129.5l8.7-28.6l-24-18.7c-1,0.7-2.2,1-3.3,1c-0.6,0-1.8-0.3-1.9-0.3L40,112.5L65.5,132 C66.6,130.5,68.3,129.6,70.1,129.5"
        />
        <path
          fill="#FFFFFF"
          d="M94.1,42.2c0,0,0-0.2,0-0.2H62.4l-9.3,29.4c0,0,0,0,0.1,0c1.9,0.5,3.3,1.8,4,3.7h2.4h27.6l9.2-28.1 C95,46,94.1,44.1,94.1,42.2z"
        />
      </g>
    </svg>
  )
}

export default Svg
