import * as React from "react"

function Svg(props) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 200 200"
      enableBackground="new 0 0 200 200"
      xmlSpace="preserve"
      {...props}
    >
      <rect fill="#E12525" width={200} height={200} />
      <g>
        <path
          fill="#FFFFFF"
          d="M175.9,45.7c-5.1,11-10.3,21.9-15.4,32.9c-1.9,4.1-4.1,8-5.8,12.2c-1.5,3.7-3.7,4.9-7.8,4.8 c-16.1-0.2-32.3-0.3-48.4,0.1c-26.1,0.7-45.4,12.2-56.1,36.7c-0.5,1.1-1.5,3.6-2.5,5.8c-5-7.6-8.5-14.2-11-20.9 c-0.7-1.9-4.7-13.7-4.7-20.9c0-27,29.7-44.4,38.3-47.6c5.6-2.1,11.5-3.1,17.5-3.1H175.9z"
        />
        <path
          fill="#C8C8C8"
          d="M146.6,105.9c-7.5,15.3-14.7,30.4-22.2,45.4c-0.7,1.4-2.9,3.1-4.3,3.1c-18.7-0.7-37.5-1.3-56.1-3 c-5.4-0.5-12.1-3.1-15.7-6.8c5.8-22.8,21.1-37,43.3-38.4C109.5,104.9,127.5,105.9,146.6,105.9z"
        />
      </g>
    </svg>
  )
}

export default Svg
