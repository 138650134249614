import * as React from "react"

function Svg(props) {
  return (
    <svg
      id="Layer_1"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 200 200"
      enableBackground="new 0 0 200 200"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <g id="g2997_2_">
          <g id="g3003_2_">
            <g id="g3005_2_">
              <path
                id="path3013_2_"
                fill="#86CF00"
                d="M25.7,69.2l24.8,47.3c1.9,3.4,5.4,5.6,9.3,5.8l50.7-0.1c3.5,0,4.9-2.6,3.2-5.8 L88.9,69.1c-1.9-3.4-5.4-5.6-9.3-5.8h-51C25.4,63.4,24.1,66,25.7,69.2"
              />
            </g>
          </g>
        </g>
        <g id="g3353_2_">
          <g id="g3355_2_">
            <g id="g3361_2_">
              <g id="g3363_2_">
                <path
                  id="path3371_2_"
                  fill="#86CF00"
                  d="M122,124.4l15,28c1.2,2.1,3.2,3.4,5.6,3.5l30.2-0.3c2.1,0,2.8-1.5,1.8-3.5l-15-28 c-1.2-2.1-3.2-3.2-5.6-3.4l-30.2,0.3h-0.1C121.7,120.9,121,122.4,122,124.4"
                />
              </g>
            </g>
          </g>
        </g>
        <g id="g3261_2_">
          <g id="g3267_2_">
            <g id="g3269_2_">
              <path
                id="path3279_2_"
                fill="#0091D0"
                d="M122.1,17.7L91.9,56.4c-2.1,3.2-2.3,7.2-0.6,10.6l25.3,47.1c1.7,3.2,4.8,3.7,6.8,1 l30.2-38.7c2.1-3.2,2.3-7.2,0.6-10.6L129,18.7c-0.6-1.5-2.2-2.7-3.9-2.7C123.9,16.1,122.9,16.8,122.1,17.7"
              />
            </g>
          </g>
        </g>
        <g id="g3413_2_">
          <g id="g3415_2_">
            <g id="g3421_2_">
              <g id="g3423_2_">
                <path
                  id="path3433_2_"
                  fill="#0091D0"
                  d="M115.1,124.7l-17.9,23.1c-1.3,1.9-1.4,4.3-0.4,6.3l15,28.2c1,1.9,2.8,2.2,4.1,0.6 l17.9-23.1c1.3-1.9,1.4-4.3,0.4-6.3l-15.1-28c-0.4-0.9-1.3-1.5-2.3-1.7C116.2,123.8,115.5,124.2,115.1,124.7"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Svg
