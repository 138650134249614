import * as React from "react"

function Svg(props) {
  return (
    <svg
      id="Layer_1"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 200 200"
      enableBackground="new 0 0 200 200"
      xmlSpace="preserve"
      {...props}
    >
      <rect fill="#37B35B" width={200} height={200} />
      <path
        fill="#FFFFFF"
        d="M163.9,69.9c-7.7,3-13.5,14.9-25.9,16.5c-0.3,0-0.6,0.1-0.9,0.1c-7-0.2-12.7-9.2-17-11.2 c-3-1.5-6.2-2.2-9.4-2.2C99.4,73,87,82.3,76.4,99.4c-5.1,8.1-6.3,12.6-9.1,16.8c-4.1,6.3-7.2,10.4-25.9,10.2c-1,0-2.1,0-3.3,0 c-0.6,0-1.2,0-1.9,0c-6.1,0-17.6,0.4-27.2,9c6.6-2.7,13.2-3.6,19.2-3.6c9.1,0,17.6,1.8,25.5,1.8c9.7,0,18.4-2.7,26.4-14.9 c0.1-0.2,0.3-0.3,0.5-0.2c0.2,0.1,0.4,0.2,0.4,0.4c0,0,0.5,6.3,0.8,10.4c0.1,1.3,0.1,2.4,0.2,3.2c0.1,0.8,0.3,1.3,0.6,1.6 c0.2,0.2,0.5,0.3,1.1,0.3h0.1l5.9-0.1c0.7,0,1.3-0.1,1.6-0.2c0.3-0.2,0.3-0.2,0.4-0.4c0-0.2-0.1-0.7-0.5-1.3 c-0.6-0.8-0.9-1.4-1.2-2.9c-0.2-1.1-0.3-2.3-0.3-3.5c0-1.6,0.1-3.2,0.3-4.2l0.1-0.1c0,0,0.1-0.2,0.3-0.4c0.2-0.2,0.4-0.4,0.7-0.5 c0.4-0.2,0.9-0.2,1.3-0.2c1,0,2,0.2,2,0.2c0.3,0.1,0.6,0.3,0.7,0.6c0.2,0.3,0.3,0.7,0.3,1.3v0c0.3,3.7,1,6.9,1.7,10.6 c0.1,0.8,0.3,1,0.5,1.1c0.2,0.1,0.6,0.1,1.2,0.1l7.6-0.1c0.8-0.2,0.9-0.4,0.9-0.7c0-0.3-0.2-0.9-0.8-1.6c-0.6-0.7-1.2-2.1-1.7-3.7 c-0.5-1.6-0.9-3.4-0.9-4.9c0-1.1,0.2-2.2,1-2.7c1.5-0.8,3-1,4.9-1c0.9,0,1.8,0.1,2.9,0.1c0.7,0.1,1,0.6,1.3,1.2 c0.3,0.6,0.7,1.4,1,2.3c0.7,1.9,1.4,4.3,2.1,6.5c0.4,1.3,0.6,2.4,0.9,3.1c0.2,0.7,0.4,1,0.7,1.2c0.2,0.2,0.7,0.3,1.4,0.3l9.1,0 c0.4,0,0.6-0.1,0.6-0.1c0.1-0.1,0.1-0.1,0.1-0.2c0-0.6-1-2-1.5-2.7c-1.4-1.8-3.6-7.3-3.6-10.1c0-0.4,0-0.7,0.1-0.9 c0.4-1.2,1.6-1.6,3-2c1.3-0.3,2.9-0.5,3.9-0.5c0.5,0,0.9,0,1.3,0.1c0.1,0,0.2,0.1,0.4,0.3c0.6,1.2,0.9,3.4,1.2,6 c0.3,2.6,0.5,5.4,0.7,7.6c0,0.2,0,0.5,0,0.7c0,0.3,0,0.5,0,0.7c0,0.4,0,0.8,0.1,0.9c0.1,0.1,0.1,0.2,0.6,0.2h0.1h8.5 c0.7,0,1.3,0,1.6-0.2c0.3-0.2,0.4-0.2,0.4-0.6c0-0.2-0.1-0.5-0.2-1c-0.4-1-1.1-2.2-1.9-3.5c-1.6-2.6-2.4-8.3-2.4-14.7 c0-2.2,0.1-4.4,0.3-6.6c0.1-1.7,1.7-3.8,3.7-6.1c2-2.3,4.5-4.9,6.5-7c4.7-5.2,6.7-10.5,10.2-14.8c3.6-4.3,8.8-7.5,19.9-8.2 c4-0.3,7.1-1.9,8.1-4.2c0,0,0-0.1,0-0.1c-1.6-0.8-5.3-2.5-8.7-2.5C179.5,64.7,163.9,69.9,163.9,69.9"
      />
    </svg>
  )
}

export default Svg
