import * as React from "react"

function Svg(props) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 200 200"
      enableBackground="new 0 0 200 200"
      xmlSpace="preserve"
      {...props}
    >
      <rect fill="#2093D3" width={200} height={200} />
      <g>
        <path
          fill="#FFFFFF"
          d="M181.7,54.4c-0.9,1.9-2.6,2.7-4.5,3.3c-1.4,0.4-2.8,0.7-4.2,1c-0.6,0.1-1.1,0.4-1.3,1 c-0.5,1.7-1.6,2.8-3.2,3.3c-1.6,0.6-3.3,0.9-5,1.3c-0.8,0.2-1.4,0.5-1.8,1.3c-0.5,1-1.4,1.5-2.4,1.8c-1.4,0.3-2.8,0.4-4.2,0.7 c-0.6,0.1-1.4,0.3-1.8,0.7c-1.5,1.5-3.4,2-5.4,2.1c-0.5,0-0.9,0-1.4,0c-0.9,0-1.6,0.1-2.2,1c-0.5,1-1.7,1.1-2.7,1.1 c-1.7-0.1-3.4-0.3-5.1-0.6c-1-0.2-1.8-0.2-2.7,0.2c-1.9,0.7-3.8,0.5-5.7,0c-5.6-1.7-10.7-0.6-15.2,3.1c-2,1.7-4.1,3-6.7,3.4 c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.2,0-0.3,0c0-0.5,0-0.9,0-1.4c0.2-11.4,0.3-22.9,0.5-34.3c0-0.3,0.1-0.7,0.3-0.8 c3.9-2.7,8-5.1,12.5-6.7c4.6-1.6,9.1-1,13.4,1.1c3,1.5,6,3.1,9,4.8c6.9,3.9,14.3,6.1,22.1,7.3c4.8,0.8,9.6,1.7,14.4,2.5 c0.8,0.1,1.5,0.3,2.2,0.6c0.7,0.3,1.3,0.8,1.9,1.2C181.7,53.7,181.7,54,181.7,54.4z"
        />
        <path
          fill="#FFFFFF"
          d="M18.3,53.4c0.7-0.9,1.7-1.3,2.8-1.5c3.6-0.7,7.3-1.4,11-2c6.3-1.1,12.5-2.1,18.5-4.3 c4.1-1.5,7.9-3.7,11.8-5.7c2.6-1.4,5.2-2.8,7.9-3.8c3.8-1.5,7.8-1.4,11.6,0.1c3.8,1.6,7.5,3.5,10.9,5.8c0.9,0.6,1.2,1.2,1.2,2.3 c0.1,7,0.2,14,0.4,21c0.1,4.2,0.2,8.3,0.3,12.5c0,0.5,0,0.9,0,1.5c-1.7-0.1-3.2-0.5-4.6-1.4c-1.2-0.7-2.3-1.5-3.3-2.3 c-3.7-3.1-8-3.9-12.7-3.5c-0.7,0.1-1.3,0.4-2,0.5c-2.2,0.6-4.3,0.8-6.5-0.1c-0.6-0.2-1.3-0.1-2,0c-1.8,0.2-3.5,0.5-5.3,0.5 c-1.3,0-2.5-0.4-3.2-1.7c-0.1-0.3-0.7-0.4-1.1-0.4c-1.3-0.1-2.6-0.1-3.9-0.3c-1.2-0.2-2.3-0.7-3.2-1.6c-0.6-0.6-1.4-0.9-2.2-1 c-1.5-0.2-3-0.4-4.4-0.8c-1-0.2-1.7-0.9-2.2-1.8c-0.3-0.7-0.9-0.9-1.5-1c-1.3-0.3-2.6-0.5-3.8-0.8c-2.2-0.6-3.9-1.7-4.6-4 c-0.1-0.4-0.8-0.7-1.2-0.8c-1.8-0.6-3.7-1-5.5-1.6c-1.4-0.5-2.5-1.4-3.1-2.7C18.3,54,18.3,53.7,18.3,53.4z"
        />
        <path
          fill="#FFFFFF"
          d="M103.8,106c0.7-0.2,1.5-0.3,2.2-0.5c1.7-0.3,3.5-0.6,5.3-0.9c1.2-0.2,1.5,0,1.8,1.1 c2.1,6.1,0.1,12.1-5.5,16.1c-2.9,2.1-6.1,3.9-9.1,5.9c-1.1,0.7-2.2,1.6-3.1,2.5c-1.3,1.3-1.5,2.7-0.7,4.3c0.7,1.5,1.9,2.9,3.4,3.7 c-0.1-1.6,0-3.2-0.2-4.7c-0.3-2,0.4-3.3,2.2-4.3c3-1.7,5.9-3.6,8.9-5.4c3.4,2.8,5.9,8.2,3.4,13.7c-1.6,3.6-4.3,6.2-7.5,8.3 c-1.8,1.2-3.7,2.2-5.6,3.3c-0.5,0.3-1,0.6-1.5,0.9c-1.5,1-1.8,2.2-1.1,3.8c0.4,0.8,0.9,1.5,1.3,2.2c0.1,0,0.2,0,0.2,0 c0-1,0.1-2.1,0.1-3.1c0-0.6,0.2-1,0.8-1.3c0.7-0.3,1.4-0.8,2.2-1.2c0.1,0,0.2,0.1,0.3,0.1c0,1.4,0,2.7,0,4.1c0,0.1-0.1,0.3-0.2,0.4 c-1.3,1.4-2.6,2.8-3.9,4.2c-0.4,0.5-0.8,0.5-1.3,0.1c-1.7-1.2-3.2-2.6-4.2-4.6c-1.5-2.9-1-5.6,0.7-8.1c0.9-1.2,2-2.2,3.1-3.2 c2.5-2.2,5.2-4.2,7.6-6.5c3.2-3.1,3.1-5.3-0.4-8.1c-0.2-0.1-0.3-0.3-0.6-0.5c-0.1,1.9-0.3,3.7-0.4,5.6c0,1.2-0.4,2-1.3,2.7 c-2.9,2.3-5.8,4.7-8.7,7.1c-2.6-3.1-5.2-5.9-5.4-10.2c-0.3-5,2.3-8.3,6-10.9c2.6-1.9,5.4-3.5,8.1-5.3c1.4-1,2.7-2,3.9-3.2 c2.1-2,2.5-5-0.2-7.2c-0.2-0.2-0.5-0.3-0.7-0.4C103.7,106.3,103.8,106.1,103.8,106z"
        />
        <path
          fill="#FFFFFF"
          d="M97.6,115.9c0-1.6,0-3.1,0-4.7c0-0.9,0-1.8,0-2.7c0-0.3,0.2-0.6,0.5-0.7c1-0.4,2-0.8,3-1.1 c1-0.3,1.3-0.1,1.4,0.9c0,1.8,0.1,3.6,0,5.4c0,0.3-0.3,0.7-0.6,0.9c-2.9,1.9-5.9,3.7-8.8,5.6c-0.8,0.5-1.6,1-2.3,1.5 c-2.6-2.8-4.6-5.9-4.8-9.8c-0.2-4.5,1.7-8.1,5.4-10.7c3.1-2.2,6.5-3.3,10.2-4c5.3-1,10.7-1.9,16-2.9c1.9-0.3,3.7-0.9,5.5-1.3 c0.5-0.1,1-0.4,1.5-0.6c1.2-0.6,1.8-1.4,1.7-2.2c-0.2-0.7-1.5-1.8-2.5-1.9c-0.4,0-0.8,0-1.2,0.1c-4.2,0.9-8.2,0.6-12.2-1 c-0.7-0.3-1.3-0.7-1.8-1.1c-1-0.8-1-1.9,0-2.7c1.2-0.9,2.5-1.7,3.8-2.3c5.3-2.4,10.6-1.9,15.8,0.5c3.2,1.5,5.2,4.1,5.8,7.7 c0.4,2.7-0.5,5-2.3,7c-1.9,2.1-4.4,3.4-7.1,4.1c-3.8,0.9-7.6,1.6-11.4,2.4c-4.4,0.9-8.8,1.7-13.2,2.7c-1.3,0.3-2.5,0.9-3.6,1.6 c-2.8,1.8-3.3,4.5-1.2,7.1c0.7,0.9,1.5,1.6,2.2,2.4C97.3,116,97.5,115.9,97.6,115.9z"
        />
        <path
          fill="#FFFFFF"
          d="M102.9,93.8c-1.9,0.4-3.7,0.9-5.6,1.3c0-0.4-0.1-0.6-0.1-0.9C97,81.8,96.7,69.4,96.5,57 c-0.1-5.6-0.2-11.2-0.3-16.7c0-0.4-0.3-0.8-0.6-1.1c-1.5-1.3-2.7-2.9-3.1-4.9c-0.7-3.5,1-7,4.2-8.5c3.2-1.5,7.1-0.7,9.3,2.1 c2.3,2.8,2.3,6.7,0,9.5c-0.5,0.7-1.2,1.2-1.8,1.9c-0.2,0.3-0.4,0.7-0.4,1c-0.3,17.6-0.6,35.1-1,52.7 C102.9,93.2,102.9,93.5,102.9,93.8z"
        />
        <path
          fill="#FFFFFF"
          d="M95.2,95.7c-0.7,0.4-1.1,0.7-1.6,0.9c-2.5,1.2-4.6,2.7-6.5,4.7c-0.5,0.5-1.1,0.7-1.8,0.6 c-3.8-0.6-7.5-1.3-11.1-2.6c-2.1-0.8-4-1.8-5.5-3.5c-3.2-3.3-3.4-7.9-0.6-11.5c1.7-2.3,4-3.6,6.7-4.4c5.4-1.6,10.5-1,15.3,1.8 c0.7,0.4,1.3,0.9,1.9,1.5c0.7,0.7,0.6,1.3-0.1,2c-1.3,1.3-2.9,1.9-4.6,2.3c-2.5,0.5-5.1,0.7-7.6,0.5c-0.6,0-1.2-0.2-1.7-0.3 c-1.3-0.4-2.5-0.1-3.4,0.9c-0.7,0.8-0.8,1.6,0.1,2.2c0.6,0.5,1.4,1,2.2,1.2c3.2,0.8,6.4,1.5,9.6,2.2c2.7,0.5,5.5,0.9,8.2,1.4 C94.6,95.6,94.7,95.6,95.2,95.7z"
        />
        <path
          fill="#FFFFFF"
          d="M102.2,150c1.7-1,3.3-2,4.9-2.9c0.7-0.4,0.9,0.2,1,0.6c1,3,1.1,5.9-1,8.5c-1,1.3-2.4,2.3-3.7,3.4 c-1.5,1.2-3.1,2.3-4.6,3.5c-1,0.8-1.7,2-1.8,3.4c-0.1,1.3,0.5,2.2,1.5,3.3c0-0.6,0-1.1-0.1-1.5c-0.5-2.4,0.2-4.2,2.3-5.4 c0.5-0.3,1-0.6,1.5-1c0.4-0.2,0.6-0.2,0.9,0.2c1.1,1.6,1.2,4.7,0.2,6.4c-0.3,0.4-0.6,0.8-0.9,1c-0.9,0.6-1.2,1.4-1.3,2.4 c0,0.8-0.1,1.6-0.3,2.4c-0.1,0.4-0.6,0.9-0.9,0.9c-0.4-0.1-0.9-0.5-1-0.9c-0.2-0.9-0.2-1.9-0.4-2.9c-0.1-0.5-0.4-1.2-0.8-1.5 c-2.4-1.6-2.6-4.9-1.5-6.9c0.8-1.3,1.7-2.5,2.7-3.7c1.1-1.4,2.3-2.7,3.4-4c0.4-0.5,0.8-1.2,1-1.8 C103.9,151.9,103.5,150.8,102.2,150z M101.3,169.6c1.8-1.4,2-4.5,0.4-5.6C101.5,165.8,101.4,167.6,101.3,169.6z"
        />
      </g>
    </svg>
  )
}

export default Svg
