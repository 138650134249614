import * as React from "react"

function Svg(props) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 200 200"
      enableBackground="new 0 0 200 200"
      xmlSpace="preserve"
      {...props}
    >
      <g id="agBixM.tif_1_">
        <g>
          <path
            fill="#26BC6C"
            d="M177.4,34.8c5.2,4.5,7.3,10.7,9.4,16.8c12.9,37.8-14.8,78.8-54.4,80.5c-13,0.6-25.1-2.2-36.3-9 c-2.9-1.8-5.1-4.6-8.6-5.4c-0.6-0.6-1.3-1.3-1.9-1.9c-5.6-6.1-12.5-8.2-20.4-5.6c-8,2.6-12.7,8.2-13.7,16.7 c-1,7.9,2.6,13.8,8.4,18.7c0.4,0.3,0.7,0.6,1.1,0.9l0.1-0.2c-0.5,2.4,1.3,4.3,1.9,6.3c4.4,13.2-4.5,25.5-18.4,25.7 c-3.4,0.1-6.7-1.2-10-0.2C16.4,164.6,9,146.2,10.1,124c1.5-29.3,28.8-56,57.3-56.1c13.3-0.8,25.7,1.9,37,9.2 c2.1,1.3,3.8,3.6,6.6,3.5c3.4,5.2,7.6,9.3,14.1,10.2c7.9,1,14.7-1.4,19.5-7.8c5.2-7,4.9-14.5,1.1-22.1c1.3,0.3,2.7,0.5,3.9,1 c10.2,4.8,21.1-1.3,26.4-10C179.2,46.3,178.1,40.5,177.4,34.8z"
          />
          <path
            fill="#009231"
            d="M111.1,80.5c-2.9,0.1-4.5-2.1-6.6-3.5c-11.3-7.3-23.7-10-37-9.2c1.1-14.2,6.1-26.7,15.7-37.5 c9.3-10.4,20.7-16.7,34.2-19.3c17.8-3.5,33.9,1.1,48.5,11.2c4.7,3.3,8.6,7.6,11.6,12.6c0.7,5.8,1.8,11.5-1.5,17 c-5.2,8.7-16.2,14.8-26.4,10c-1.2-0.6-2.6-0.7-3.9-1c-3-3.2-5.9-6.7-10.3-8.2c-8.1-2.8-16.7-0.6-22.5,6.4 C108.9,63.7,106.5,72.6,111.1,80.5z"
          />
          <path
            fill="#01AC48"
            d="M34.6,178.2c3.4-1,6.7,0.2,10,0.2c13.9-0.2,22.8-12.5,18.4-25.7c-0.7-2.1-2.4-3.9-1.9-6.3 c10.4,4,18.4,2.6,24.7-4.3c5.9-6.5,6.5-14.8,1.6-24.2c3.5,0.8,5.7,3.6,8.6,5.4c11.2,6.8,23.3,9.6,36.3,9 c-3,29.2-21,50.3-48.8,56.5C65.8,192.6,49.4,188.9,34.6,178.2z"
          />
          <path fill="#01AC48" d="M59.9,145.6c0.4,0.3,0.7,0.6,1.1,0.9C60.7,146.2,60.3,145.9,59.9,145.6z" />
        </g>
      </g>
    </svg>
  )
}

export default Svg
