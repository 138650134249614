import * as React from "react"

function Svg(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" {...props}>
      <defs>
        <linearGradient y2="45.396" x2="21.386" y1="19.922" x1="21.386" gradientUnits="userSpaceOnUse" id="A">
          <stop offset="0" stopColor="#dadada" />
          <stop offset="1" stopColor="#efefef" />
        </linearGradient>
        <linearGradient id="B" gradientUnits="userSpaceOnUse" x1="20.528" y1="38.746" x2="20.528" y2="45.503">
          <stop offset="0" stopColor="#c2c2c2" />
          <stop offset="1" stopColor="#e0e0e0" />
        </linearGradient>
      </defs>
      <g transform="matrix(2.214601 0 0 2.214601 -20.932338 -36.82862)">
        <g fillRule="evenodd">
          <path
            d="M10.948 45.408s.302-1.028.302-2.7l-.03-22.763h20.556s.15 17.624-.18 20.828c-.18 2.116-1.088 2.297-2.48.635-.786-.937-1.542-2.63-1.542-2.63s-2.388 2.358-8.615 4.625c-4.08 1.45-8.01 1.995-8.01 1.995z"
            fill="url(#A)"
          />
          <path
            d="M10.344 45.5c-.03 0-.03 0-.03-.03s0-.03.03-.03c.06 0 4.595-.635 8.615-2.056 6.137-2.207 8.585-4.565 8.585-4.595h.03s.03 0 .03.03.786 1.723 1.542 2.63c.635.756 1.118 1.118 1.48 1.118h.09c.03 0 .03 0 .03.03s0 .03-.03.03c-3.325.665-20.223 2.842-20.374 2.872z"
            fill="url(#B)"
          />
        </g>
        <path
          d="M27.544 38.788s.756 1.723 1.542 2.63c.574.665 1.088 1.118 1.51 1.118h.12c-3.386.756-20.405 2.932-20.405 2.932s4.565-.605 8.615-2.056c6.257-2.267 8.615-4.625 8.615-4.625m0-.09c-.03 0-.03 0-.06.03 0 .03-2.418 2.388-8.555 4.595-4.02 1.45-8.555 2.056-8.585 2.056s-.06.03-.06.09c0 .03.03.06.06.06.18-.03 17.05-2.207 20.405-2.932.03 0 .06-.06.06-.09s-.03-.06-.06-.06h-.12c-.363 0-.816-.363-1.45-1.088-.756-.907-1.542-2.6-1.542-2.63-.03 0-.03 0-.09-.03z"
          fill="#fff"
        />
        <path
          d="M36.462 16.63s-10.127 6.378-14.48 17.533l-.635-1.3c-1.3-2.6-1.814-3.446-2.78-3.507-.605-.03-2.056.212-3.355 1.723.816.242 1.663.665 2.267 1.572.272.453 3.658 8.222 3.658 8.222s7.195-19.437 16.384-23.8z"
          fill="#295277"
        />
      </g>
    </svg>
  )
}

export default Svg
