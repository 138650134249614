import * as React from "react"

function Svg(props) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 200 200"
      enableBackground="new 0 0 200 200"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <g>
          <path
            fill="#2B6190"
            d="M100,64c22.1,0,42.7,4.5,58,12.6c12.7,6.8,20,15.3,20,23.4s-7.3,16.6-20,23.4c-15.3,8.2-35.9,12.6-58,12.6 s-42.7-4.5-58-12.6c-12.7-6.8-20-15.3-20-23.4s7.3-16.6,20-23.4C57.3,68.5,77.9,64,100,64 M100,52c-49.7,0-90,21.5-90,48 s40.3,48,90,48s90-21.5,90-48S149.7,52,100,52L100,52z"
          />
          <path
            fill="#47BB7F"
            d="M100,22c8.1,0,16.6,7.3,23.4,20c8.2,15.3,12.6,35.9,12.6,58s-4.5,42.7-12.6,58c-6.8,12.7-15.3,20-23.4,20 s-16.6-7.3-23.4-20C68.5,142.7,64,122.1,64,100s4.5-42.7,12.6-58C83.4,29.3,91.9,22,100,22 M100,10c-26.5,0-48,40.3-48,90 s21.5,90,48,90s48-40.3,48-90S126.5,10,100,10L100,10z"
          />
          <path
            fill="#FCBA63"
            d="M140.2,39.9c6.6,0,11.6,1.7,14.9,5c5.7,5.7,6.6,16.9,2.4,30.7c-5,16.6-16.4,34.3-32.1,49.9 c-21.1,21.1-46.9,34.7-65.7,34.7c-6.6,0-11.6-1.7-14.9-5c-5.7-5.7-6.6-16.9-2.4-30.7c5-16.6,16.4-34.3,32.1-49.9 C95.6,53.5,121.4,39.9,140.2,39.9 M140.2,27.9c-21.4,0-49.8,13.9-74.2,38.2c-35.1,35.1-48.4,78.8-29.7,97.6 c5.8,5.8,13.9,8.5,23.4,8.5c21.4,0,49.8-13.9,74.2-38.2c35.1-35.1,48.4-78.8,29.7-97.6C157.9,30.6,149.7,27.9,140.2,27.9 L140.2,27.9z"
          />
          <path
            fill="#57B7DF"
            d="M59.8,39.9c18.8,0,44.6,13.6,65.7,34.7c15.6,15.6,27,33.4,32.1,49.9c4.2,13.8,3.3,25-2.4,30.7 c-3.3,3.3-8.3,5-14.9,5c-18.8,0-44.6-13.6-65.7-34.7c-15.6-15.6-27-33.4-32.1-49.9c-4.2-13.8-3.3-25,2.4-30.7 C48.2,41.5,53.2,39.9,59.8,39.9 M59.8,27.9c-9.5,0-17.6,2.7-23.4,8.5c-18.7,18.7-5.4,62.4,29.7,97.6 c24.3,24.3,52.8,38.2,74.2,38.2c9.5,0,17.6-2.7,23.4-8.5c18.7-18.7,5.4-62.4-29.7-97.6C109.6,41.7,81.2,27.9,59.8,27.9L59.8,27.9z "
          />
        </g>
        <g>
          <path
            fill="none"
            d="M119.4,146.9c-2.5,2-5.1,3.9-7.6,5.6c4,2,8,3.5,11.9,4.8c1.9-3.7,3.6-7.6,5.1-11.8 C125.7,146,122.6,146.5,119.4,146.9z"
          />
          <path
            fill="none"
            d="M135.8,159.9c1.5,0.2,3,0.3,4.4,0.3c6.6,0,11.6-1.7,14.9-5c4-4,5.6-10.8,4.7-19.3c-5.3,2.5-11.2,4.7-17.5,6.5 C140.5,148.7,138.4,154.6,135.8,159.9z"
          />
          <path
            fill="none"
            d="M146.9,119.4c-0.4,3.2-0.8,6.3-1.4,9.4c4.2-1.5,8.1-3.2,11.8-5.1c-1.2-3.9-2.8-7.9-4.7-11.9 C150.7,114.3,148.9,116.9,146.9,119.4z"
          />
          <path
            fill="none"
            d="M80.6,53.1c2.5-2,5.1-3.9,7.6-5.6c-4-2-8-3.5-11.9-4.8c-1.9,3.7-3.6,7.6-5.1,11.8 C74.3,54,77.4,53.5,80.6,53.1z"
          />
          <path
            fill="none"
            d="M64.2,40.1c-1.5-0.2-3-0.3-4.4-0.3c-6.6,0-11.6,1.7-14.9,5c-4,4-5.6,10.8-4.7,19.3c5.3-2.5,11.2-4.7,17.5-6.5 C59.5,51.3,61.6,45.4,64.2,40.1z"
          />
          <path
            fill="none"
            d="M167.7,117c6.6-5.4,10.3-11.3,10.3-17s-3.7-11.6-10.3-17c-2,5.6-4.6,11.3-7.8,17 C163.1,105.8,165.7,111.5,167.7,117z"
          />
          <path
            fill="none"
            d="M53.1,119.4c-2-2.5-3.9-5.1-5.7-7.6c-1.9,4-3.5,8-4.7,11.9c3.7,1.9,7.6,3.6,11.8,5.1 C54,125.7,53.5,122.6,53.1,119.4z"
          />
          <path
            fill="none"
            d="M117,32.3c-5.4-6.7-11.3-10.3-17-10.3c-5.7,0-11.6,3.7-17,10.3c5.6,2,11.3,4.6,17,7.8 C105.8,36.9,111.5,34.3,117,32.3z"
          />
          <path
            fill="none"
            d="M83,167.7c5.4,6.7,11.3,10.3,17,10.3c5.7,0,11.6-3.7,17-10.3c-5.6-2-11.3-4.6-17-7.8 C94.2,163.1,88.5,165.7,83,167.7z"
          />
          <path
            fill="none"
            d="M40.1,135.8c-0.9,8.5,0.7,15.3,4.7,19.3c3.3,3.3,8.3,5,14.9,5c1.4,0,2.9-0.1,4.4-0.3 c-2.5-5.3-4.7-11.2-6.5-17.5C51.3,140.5,45.4,138.3,40.1,135.8z"
          />
          <path
            fill="none"
            d="M32.3,83C25.7,88.4,22,94.3,22,100s3.7,11.6,10.3,17c2-5.6,4.6-11.3,7.8-17C36.9,94.2,34.3,88.5,32.3,83z"
          />
          <path
            fill="none"
            d="M80.6,146.9c-3.2-0.4-6.3-0.8-9.4-1.4c1.5,4.2,3.2,8.1,5.1,11.8c3.9-1.2,7.9-2.8,11.9-4.8 C85.6,150.7,83.1,148.9,80.6,146.9z"
          />
          <path
            fill="none"
            d="M53.1,80.6c0.4-3.2,0.8-6.3,1.4-9.4c-4.2,1.5-8.1,3.2-11.8,5.1c1.2,3.9,2.8,7.9,4.7,11.9 C49.3,85.7,51.1,83.1,53.1,80.6z"
          />
          <path
            fill="none"
            d="M135.3,85.4c-3.1-3.7-6.3-7.3-9.9-10.8c-3.5-3.5-7.2-6.8-10.9-9.9c-4.8-0.4-9.6-0.7-14.6-0.7 s-9.8,0.2-14.6,0.7c-3.7,3.1-7.4,6.4-10.9,9.9c-3.5,3.5-6.8,7.1-9.9,10.8C64.2,90.1,64,95,64,100c0,5,0.2,9.9,0.7,14.6 c3.1,3.7,6.3,7.3,9.9,10.8c3.5,3.5,7.2,6.8,10.9,9.9c4.8,0.4,9.6,0.7,14.6,0.7c4.9,0,9.8-0.2,14.6-0.7c3.7-3.1,7.4-6.4,10.9-9.9 c3.5-3.5,6.8-7.1,9.9-10.8c0.4-4.8,0.7-9.7,0.7-14.6S135.8,90.1,135.3,85.4z"
          />
          <path
            fill="none"
            d="M146.9,80.6c2,2.5,3.9,5.1,5.7,7.6c1.9-4,3.5-8,4.7-11.9c-3.7-1.9-7.6-3.6-11.8-5.1 C146,74.3,146.5,77.4,146.9,80.6z"
          />
          <path
            fill="none"
            d="M159.9,64.2c0.9-8.5-0.7-15.3-4.7-19.3c-3.3-3.3-8.3-5-14.9-5c-1.4,0-2.9,0.1-4.4,0.3 c2.5,5.3,4.7,11.2,6.5,17.5C148.7,59.5,154.6,61.7,159.9,64.2z"
          />
          <path
            fill="none"
            d="M119.4,53.1c3.2,0.4,6.3,0.8,9.4,1.4c-1.5-4.2-3.2-8.1-5.1-11.8c-3.9,1.2-7.9,2.8-11.9,4.8 C114.4,49.3,116.9,51.1,119.4,53.1z"
          />
          <path
            fill="#2B6190"
            d="M71.2,145.5c3.1,0.6,6.2,1,9.4,1.4c-4.1-3.3-8.2-6.9-12.2-10.7C69.2,139.4,70.1,142.5,71.2,145.5z"
          />
          <path
            fill="#2B6190"
            d="M128.8,54.5c-3.1-0.6-6.2-1-9.4-1.4c4.1,3.3,8.2,6.9,12.2,10.7C130.8,60.6,129.9,57.5,128.8,54.5z"
          />
          <path
            fill="#47BB7F"
            d="M145.5,128.8c0.6-3.1,1-6.2,1.4-9.4c-3.3,4.1-6.8,8.2-10.7,12.2C139.4,130.8,142.5,129.9,145.5,128.8z"
          />
          <path
            fill="#47BB7F"
            d="M54.5,71.2c-0.6,3.1-1,6.2-1.4,9.4c3.3-4.1,6.8-8.2,10.7-12.2C60.6,69.2,57.5,70.1,54.5,71.2z"
          />
          <path
            fill="#FCBA63"
            d="M88.2,47.5c-2.5,1.8-5.1,3.6-7.6,5.6c5.2-0.6,10.6-1,16.1-1.1C93.8,50.4,91,48.9,88.2,47.5z"
          />
          <path
            fill="#FCBA63"
            d="M111.8,152.5c2.5-1.8,5.1-3.6,7.6-5.6c-5.2,0.6-10.6,1-16.1,1.1C106.2,149.6,109,151.1,111.8,152.5z"
          />
          <path
            fill="#2B6190"
            d="M171.1,70.6c-3.4-2.3-7.1-4.4-11.2-6.4c-5.3-2.5-11.2-4.7-17.5-6.5c1.2,4.3,2.3,8.8,3.1,13.5 c4.2,1.5,8.1,3.2,11.8,5.1c0.2,0.1,0.5,0.2,0.7,0.4c3.7,2,6.9,4.1,9.7,6.3c6.6,5.4,10.3,11.3,10.3,17s-3.7,11.6-10.3,17 c1.5,4.3,2.6,8.4,3.4,12.4c11.9-8.1,18.9-18.3,18.9-29.4S182.9,78.7,171.1,70.6z"
          />
          <path
            fill="#2B6190"
            d="M54.5,128.8c-4.2-1.5-8.1-3.2-11.8-5.1c-0.2-0.1-0.5-0.2-0.7-0.4c-3.7-2-6.9-4.1-9.7-6.3 c-6.6-5.4-10.3-11.3-10.3-17s3.7-11.6,10.3-17c-1.5-4.3-2.6-8.4-3.4-12.4C17.1,78.7,10,88.9,10,100s7.1,21.3,18.9,29.4 c3.4,2.3,7.1,4.4,11.2,6.4c5.3,2.5,11.2,4.7,17.5,6.5C56.4,138,55.4,133.5,54.5,128.8z"
          />
          <path
            fill="#FCBA63"
            d="M163.6,36.4c-5.8-5.8-13.9-8.5-23.4-8.5c-3.4,0-7.1,0.4-10.8,1.1c-4,0.7-8.2,1.9-12.4,3.4 c-5.6,2-11.3,4.6-17,7.8c3.9,2.2,7.9,4.7,11.8,7.4c4-2,8-3.5,11.9-4.8c4.2-1.3,8.3-2.2,12.1-2.6c1.5-0.2,3-0.3,4.4-0.3 c6.6,0,11.6,1.7,14.9,5c4,4,5.6,10.8,4.7,19.3c4.1,1.9,7.8,4.1,11.2,6.4C173.7,56.4,171.5,44.2,163.6,36.4z"
          />
          <path
            fill="#FCBA63"
            d="M88.2,152.5c-4,2-8,3.5-11.9,4.8c-4.2,1.3-8.3,2.2-12.1,2.6c-1.5,0.2-3,0.3-4.4,0.3 c-6.6,0-11.6-1.7-14.9-5c-4-4-5.6-10.8-4.7-19.3c-4.1-1.9-7.8-4.1-11.2-6.4c-2.6,14.1-0.4,26.4,7.4,34.2 c5.8,5.8,13.9,8.5,23.4,8.5c3.4,0,7.1-0.4,10.8-1.1c4-0.7,8.2-1.9,12.4-3.4c5.6-2,11.3-4.6,17-7.8 C96.1,157.7,92.1,155.2,88.2,152.5z"
          />
          <path
            fill="#2B6190"
            d="M157.5,124.5c-0.1-0.3-0.2-0.5-0.3-0.8c-3.7,1.9-7.6,3.6-11.8,5.1c-3,1-6.1,2-9.3,2.8 c-1.2,0.3-2.4,0.6-3.7,0.9c-5.7,1.3-11.7,2.2-17.9,2.8c-4.8,0.4-9.6,0.7-14.6,0.7c-4.9,0-9.8-0.2-14.6-0.7 c4.8,3.9,9.7,7.5,14.6,10.6c1.1,0.7,2.2,1.4,3.3,2c5.5-0.1,10.9-0.5,16.1-1.1c3.2-0.4,6.3-0.8,9.4-1.4c4.7-0.8,9.2-1.9,13.5-3.1 c6.3-1.8,12.2-4,17.5-6.5C159.5,132.3,158.7,128.5,157.5,124.5z"
          />
          <path
            fill="#2B6190"
            d="M100,54.1c-1.1-0.7-2.2-1.4-3.3-2c-5.5,0.1-10.9,0.5-16.1,1.1c-3.2,0.4-6.3,0.8-9.4,1.4 c-4.7,0.8-9.2,1.9-13.5,3.1c-6.3,1.8-12.2,4-17.5,6.5c0.4,3.5,1.1,7.3,2.4,11.3c0.1,0.3,0.2,0.5,0.3,0.8c3.7-1.9,7.6-3.6,11.8-5.1 c3-1,6.1-2,9.3-2.8c1.2-0.3,2.4-0.6,3.7-0.9c5.7-1.3,11.7-2.2,17.9-2.8c4.8-0.4,9.6-0.7,14.6-0.7s9.8,0.2,14.6,0.7 C109.8,60.7,104.9,57.2,100,54.1z"
          />
          <path
            fill="#57B7DF"
            d="M148,96.8c1.7-2.8,3.2-5.7,4.6-8.5c-1.8-2.6-3.7-5.1-5.7-7.6C147.5,85.8,147.9,91.2,148,96.8z"
          />
          <path
            fill="#57B7DF"
            d="M47.5,111.8c1.8,2.6,3.7,5.1,5.7,7.6c-0.6-5.2-1-10.6-1.1-16.2C50.4,106.1,48.8,108.9,47.5,111.8z"
          />
          <path
            fill="#57B7DF"
            d="M171.1,129.4c-0.7-4-1.9-8.2-3.4-12.4c-2-5.6-4.6-11.3-7.8-17c-2.2,3.9-4.7,7.9-7.4,11.8 c1.9,4,3.5,8,4.7,11.9c0.1,0.3,0.2,0.5,0.3,0.8c1.2,4,2,7.8,2.4,11.3c0.9,8.5-0.7,15.3-4.7,19.3c-3.3,3.3-8.3,5-14.9,5 c-1.4,0-2.9-0.1-4.4-0.3c-1.9,4.1-4.1,7.8-6.4,11.2c3.7,0.7,7.4,1.1,10.8,1.1c9.5,0,17.6-2.7,23.4-8.5 C171.5,155.8,173.7,143.6,171.1,129.4z"
          />
          <path
            fill="#57B7DF"
            d="M59.8,27.9c-9.5,0-17.6,2.7-23.4,8.5c-7.8,7.8-10.1,20.1-7.4,34.2c0.7,4,1.9,8.2,3.4,12.4 c2,5.6,4.6,11.3,7.8,17c2.2-3.9,4.6-7.9,7.4-11.8c-1.9-4-3.5-8-4.7-11.9c-0.1-0.3-0.2-0.5-0.3-0.8c-1.2-4-2-7.8-2.4-11.3 c-0.9-8.5,0.7-15.3,4.7-19.3c3.3-3.3,8.3-5,14.9-5c1.4,0,2.9,0.1,4.4,0.3c1.9-4.1,4.1-7.8,6.4-11.2C66.8,28.3,63.2,27.9,59.8,27.9 z"
          />
          <path
            fill="#47BB7F"
            d="M100,10c-11.1,0-21.3,7.1-29.4,19c-2.3,3.4-4.4,7.1-6.4,11.2c-2.5,5.3-4.7,11.2-6.5,17.5 c4.3-1.2,8.8-2.3,13.5-3.1c1.5-4.2,3.2-8.1,5.1-11.8c0.1-0.2,0.2-0.5,0.4-0.7c2-3.7,4.1-6.9,6.3-9.7C88.4,25.7,94.3,22,100,22 c5.7,0,11.6,3.7,17,10.3c4.3-1.5,8.4-2.6,12.4-3.4C121.3,17.1,111.1,10,100,10z"
          />
          <path
            fill="#47BB7F"
            d="M128.8,145.5c-1.5,4.2-3.2,8.1-5.1,11.8c-0.1,0.2-0.2,0.5-0.4,0.7c-2,3.7-4.1,6.9-6.3,9.7 c-5.4,6.7-11.3,10.3-17,10.3c-5.7,0-11.6-3.7-17-10.3c-4.3,1.5-8.4,2.6-12.4,3.4c8.1,11.9,18.3,19,29.4,19s21.3-7.1,29.4-19 c2.3-3.4,4.4-7.1,6.4-11.2c2.5-5.3,4.7-11.2,6.5-17.5C138,143.6,133.5,144.6,128.8,145.5z"
          />
          <path
            fill="#47BB7F"
            d="M146.9,80.6c-0.4-3.2-0.8-6.3-1.4-9.4c-0.8-4.7-1.9-9.2-3.1-13.5c-1.8-6.3-4-12.2-6.5-17.5 c-3.8,0.4-7.9,1.3-12.1,2.6c1.9,3.7,3.6,7.6,5.1,11.8c1.1,3,2,6.1,2.8,9.3c0.3,1.2,0.6,2.4,0.9,3.7c1.3,5.7,2.2,11.7,2.8,17.9 c0.4,4.8,0.7,9.7,0.7,14.6s-0.2,9.9-0.7,14.6c4-4.8,7.5-9.7,10.7-14.6c0.7-1.1,1.3-2.2,2-3.2C147.9,91.2,147.5,85.8,146.9,80.6z"
          />
          <path
            fill="#47BB7F"
            d="M71.2,145.5c-1.1-3-2-6.1-2.8-9.3c-0.3-1.2-0.6-2.4-0.9-3.7c-1.3-5.7-2.2-11.7-2.8-17.9 C64.2,109.9,64,105,64,100c0-5,0.2-9.9,0.7-14.6c-4,4.8-7.5,9.7-10.7,14.6c-0.7,1.1-1.3,2.2-2,3.2c0.1,5.5,0.5,10.9,1.1,16.2 c0.4,3.2,0.8,6.3,1.4,9.4c0.8,4.7,1.9,9.2,3.1,13.5c1.8,6.3,4,12.2,6.5,17.5c3.8-0.4,7.9-1.3,12.1-2.6 C74.4,153.6,72.7,149.7,71.2,145.5z"
          />
          <path
            fill="#57B7DF"
            d="M132.5,67.5c-0.3-1.2-0.6-2.5-0.9-3.7c-4-3.8-8.1-7.4-12.2-10.7c-2.5-2-5.1-3.9-7.6-5.6 c-3.9-2.7-7.9-5.2-11.8-7.4c-5.8-3.2-11.5-5.8-17-7.8c-2.2,2.7-4.3,6-6.3,9.7c-0.1,0.2-0.2,0.5-0.4,0.7c3.9,1.2,7.9,2.8,11.9,4.8 c2.8,1.4,5.6,2.9,8.5,4.5c1.1,0.7,2.2,1.3,3.3,2c4.9,3.1,9.8,6.7,14.6,10.6c3.7,3.1,7.4,6.4,10.9,9.9c3.5,3.5,6.8,7.1,9.9,10.8 C134.7,79.2,133.8,73.2,132.5,67.5z"
          />
          <path
            fill="#57B7DF"
            d="M111.8,152.5c-2.8-1.4-5.6-2.9-8.5-4.5c-1.1-0.7-2.2-1.3-3.3-2c-4.9-3.1-9.8-6.7-14.6-10.6 c-3.7-3.1-7.4-6.4-10.9-9.9c-3.5-3.5-6.8-7.1-9.9-10.8c0.6,6.2,1.5,12.2,2.8,17.9c0.3,1.2,0.6,2.5,0.9,3.7 c4,3.8,8.1,7.4,12.2,10.7c2.5,2,5.1,3.9,7.6,5.6c3.9,2.7,7.9,5.2,11.8,7.4c5.8,3.2,11.5,5.8,17,7.8c2.2-2.7,4.3-6,6.3-9.7 c0.1-0.2,0.2-0.5,0.4-0.7C119.8,156,115.9,154.4,111.8,152.5z"
          />
          <path
            fill="#FCBA63"
            d="M158,76.6c-0.2-0.1-0.5-0.2-0.7-0.4c-1.2,3.9-2.8,7.9-4.7,11.9c-1.4,2.8-2.9,5.7-4.6,8.5 c-0.6,1.1-1.3,2.2-2,3.2c-3.1,4.9-6.7,9.8-10.7,14.6c-3.1,3.7-6.3,7.3-9.9,10.8c-3.5,3.5-7.2,6.8-10.9,9.9 c6.2-0.6,12.2-1.5,17.9-2.8c1.2-0.3,2.5-0.6,3.7-0.9c3.8-4,7.4-8.1,10.7-12.2c2-2.5,3.9-5.1,5.7-7.6c2.7-3.9,5.2-7.9,7.4-11.8 c3.2-5.8,5.8-11.5,7.8-17C164.9,80.8,161.7,78.6,158,76.6z"
          />
          <path
            fill="#FCBA63"
            d="M67.5,67.5c-1.2,0.3-2.5,0.6-3.7,0.9c-3.8,4-7.4,8.1-10.7,12.2c-2,2.5-3.9,5.1-5.7,7.6 c-2.7,3.9-5.2,7.9-7.4,11.8c-3.2,5.8-5.8,11.5-7.8,17c2.7,2.2,6,4.3,9.7,6.3c0.2,0.1,0.5,0.2,0.7,0.4c1.2-3.9,2.8-7.9,4.7-11.9 c1.4-2.8,2.9-5.7,4.6-8.5c0.6-1.1,1.3-2.2,2-3.2c3.1-4.9,6.7-9.8,10.7-14.6c3.1-3.7,6.3-7.3,9.9-10.8c3.5-3.5,7.2-6.8,10.9-9.9 C79.2,65.3,73.2,66.2,67.5,67.5z"
          />
        </g>
      </g>
    </svg>
  )
}

export default Svg
