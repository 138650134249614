import * as React from "react"

function Svg(props) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 200 200"
      enableBackground="new 0 0 200 200"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <rect fill="#236192" width={200} height={200} />
      </g>
      <path
        fill="#FFFFFF"
        d="M99,32c-1.1,4.7-7.2,14.8-13.6,23l-3.1,4c0,0.1,1.9-0.7,4.2-1.8c5-2.3,9.6-3.8,14-4.8 c3.5-0.8,10.1-1.8,11.9-1.8c0.8,0,1-0.1,0.9-0.4c-0.4-1.1-11.3-17.2-13.1-19.4L99.5,30L99,32z M112.9,56.1 c-5.5,6.6-9.5,12.6-12.3,18.4l-3,7.2c0,0.1,2.4-0.6,5.2-1.5l5.2-1.5c0.1,0.1-0.9,2-2,4.4c-3.8,7.7-6.3,14.8-7.4,21.2 c-0.6,3.4-1.3,12.5-1,12.7c0.1,0.1,1.8-0.5,3.7-1.5l3.6-1.6c0.1,0.1-0.3,1.8-0.7,3.9c-1.8,8.1-2.7,16.4-2.6,24.7 c0,7.2,0.2,9.9,1.3,16.8c1,6.4,2,10.3,2.6,10.3c1.4,0,7.1-1.2,10-2.2c16.3-5.4,28.7-18.8,32.8-35.5c2.1-8.8,1.9-17.8-0.6-26 c-2.9-9.4-7.1-16.8-19.3-34l-8.9-12.8l-4-5.7C115.2,53.4,114.1,54.6,112.9,56.1L112.9,56.1z M76.2,66.3 C60.6,84.8,53.6,97.1,51,111.1c-0.9,5.9-0.9,12,0,17.9c1.9,10.1,6.4,18.8,13.6,26c7.3,7.4,16.3,12.2,26.3,14 c3.1,0.6,11.9,1.1,12.8,0.7c0.3-0.1,0-0.9-1.8-3.8c-4.8-7.8-7.8-14.8-9.6-21.7c-1-4-2.2-10.3-2.2-11.7c0-1.5-0.3-1.5-3,0 c-1.4,0.8-2.6,1.3-2.8,1.3c-0.3-0.2-2-7.2-2.6-10.7c-1.2-7-1.8-15.8-1.3-21.8c0.1-1.7,0.2-3.2,0.1-3.2c-0.1,0-1.7,0.5-3.6,1.4 l-3.4,1.4l-0.2-0.9c-0.2-1,0-8.7,0.4-13.4c0.5-6.5,2.1-13.6,4.2-20.2l0.9-3.1C78.7,63.4,77.6,64.6,76.2,66.3L76.2,66.3z"
      />
    </svg>
  )
}

export default Svg
